body,
html,
#root,
.App {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-spaces;
}

.section-title {
  font-size: 4rem;
}

.green-shadow {
  -webkit-box-shadow: 0px 0px 55px 0px rgba(197, 226, 235, 1);
  -moz-box-shadow: 0px 0px 55px 0px rgba(197, 226, 235, 1);
  box-shadow: 0px 0px 55px 0px rgba(197, 226, 235, 1);
  border: none;
}

kbd {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.1em 0.5em;
  margin: 0 0.2em;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  background-color: #f7f7f7;
  color: #000000;
}

.labelbutton {
  cursor: pointer;
  font-size: 1rem !important;
  font-weight: 400 !important;
  display: inline-block;
  padding: 0.3rem 0.75rem !important;
  margin: 0 !important;
  border-radius: 0.25rem;
  transition: 0.25s;
  transition-property: background-color;
}
