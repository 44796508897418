.faq-row-wrapper {
  .faq-title {
    text-align: center !important;
    padding-bottom: 1rem !important;
    h2 {
      width: 100%;
    }
  }

  .faq-body {
    .faq-row {
      .row-title {
        font-size: 1.4rem;
        font-weight: 500;
      }

      .row-content {
        .row-content-text {
            line-height: 2rem;
            padding-bottom: .5rem;
        }
      }
    }
  }
}
