.sidebar-wrapper {
  background: linear-gradient(
      0deg,
      rgba(3, 161, 224, 0.7),
      rgba(44, 44, 44, 0.5)
    ),
    url("/assets/img/landing-page-top-bg.jpg") no-repeat, #000000;
  background-position: cover;
  color: white;
  padding: 1rem 0.8rem;

  display: flex;
  flex-direction: column;

  .top-nav {
    flex: 1;
  }

  .footer-nav {
    font-size: 0.7rem;
    a {
      color: white;
    }
  }

  .userdisplay-wrapper {
    padding: 0 0.75rem;

    .userdisplay-data {
      padding-left: 0.5rem;
      .name-top {
        font-weight: bold;
      }
      .imid-bot {
        font-size: 0.7rem;
      }
    }
  }

  .menu-section.nav {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;

    .nav-item {
      box-sizing: content-box;
      margin: 0.3rem 0;
      text-transform: uppercase;
      font-size: 0.9rem;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

      a {
        padding: 0.75rem 1.75rem;
        display: block;

        color: white;
        text-decoration: none;

        border-radius: 40px;

        svg {
          margin-right: 0.8rem;
        }

        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.15);
        }
      }
      a.disabled-menu-item {
        color: rgba(255, 255, 255, 0.6);
        cursor: default;
        &:hover {
          background-color: transparent;
        }
      }

      a.active {
        background-color: white;
        color: #2ca8ff;
      }
    }

    .sub-1-item {
      a {
        padding: 0.5rem 1.75rem;
        text-shadow: 0 0 0px #fff;
        transition: 0.2s;
        font-size: 0.8rem;
        letter-spacing: 2px;
        &.active {
          text-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
          color: white;
          font-weight: bold;
          background-color: transparent;
          letter-spacing: 4px;
        }
      }
    }

    .sub-2-item {
      a {
        padding: 0.5rem 1.75rem;
        text-shadow: 0 0 0px #fff;
        transition: 0.2s;
        font-size: 0.8rem;
        letter-spacing: 2px;
        &.active {
          text-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
          color: white;
          font-weight: bold;
          background-color: transparent;
          letter-spacing: 4px;
        }
      }
    }
  }
}

.content {
  .breadcrumb-wrapper {
    // background: rgb(39, 39, 39);
    background: linear-gradient(
        0deg,
        rgba(39, 39, 39, 0.8),
        rgba(44, 44, 44, 0.5)
      ),
      url("/assets/img/landing-page-top-bg.jpg") center;
    background-size: cover;
    color: rgb(241, 241, 241);
    padding: 0.4rem 2rem;

    .breadcrumb {
      background-color: transparent;
      border-radius: 0;
      font-size: 0.8rem;
      padding: 0;
      margin: 0;
      justify-content: center;
      a {
        color: rgb(231, 231, 231);
      }
    }
  }
}

.dropdown-menu.show{
  background-color: #007bff
}

.dashboard-switch-btn {
  background-color: rgba(255, 255, 255, 0.521) !important;
  border: none !important;
  &.active {
    background-color: white !important;
    color: rgb(3, 161, 224) !important;
    font-weight: bold;
  }
}