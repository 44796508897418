.loader-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  justify-content: center;
  align-items: center;
  align-content: center;
  transition-property: background-color;
  transition-duration: 0.2s;
}
