.response-box-wrapper {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 99999;

  .response-toast {
    position: relative;
    color: white;
    cursor: pointer;
    border: none;
    right: 0;

    transition: 0.3s;

    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

    &:hover {
      -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.35);
      right: 0.5rem;
    }

    &.success-response-toast {
      background-color: rgba(0, 168, 0, 0.9);
      &:hover {
        background-color: rgba(0, 192, 0, 0.9);
      }
    }

    &.warning-response-toast {
      background-color: rgba(206, 193, 14, 0.9);
      &:hover {
        background-color: rgba(236, 222, 19, 0.9);
      }
    }

    &.information-response-toast {
      background-color: rgba(0, 115, 209, 0.9);
      &:hover {
        background-color: rgba(0, 126, 230, 0.9);
      }
    }

    &.error-response-toast {
      background-color: rgba(168, 0, 0, 0.9);
      &:hover {
        background-color: rgba(192, 0, 0, 0.9);
      }
    }
  }

  .toast-header {
    background: transparent;
    color: white;
    font-size: 1.2rem;
  }
}
