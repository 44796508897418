.autoships {
  .no-sync-container-wrapper {
    display: flex;
    align-items: center;

    .no-sync-container {
      .import-guide-list {
        h4,
        p {
          text-align: center;
        }

        li {
          font-size: 4rem;
          line-height: 2.3rem;
          margin-top: 4rem;
          span {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  .import-guide-list {
    h4,
    p {
      text-align: center;
    }

    li {
      font-size: 4rem;
      line-height: 2.3rem;
      margin-top: 4rem;
      span {
        font-size: 1.4rem;
      }
    }
  }
}

.autoship-row {
  .manage-column {
    display: none;
  }
  &:hover {
    .manage-column {
      display: table-column;
    }
  }
}
